<template>
  <main>
    <div
      :id="containerName"
      :class="{ thumbs: fromOffScreen }"
      :style="styleContainer"
    >
      <!-- <span
        style="position: absolute; right: -75px; z-index: 1020"
        class="bg-dark text-white p-0 m-0"
      >
        {{ instance }}-{{ zIndex }}-{{ fromOffScreen }}
      </span> -->

      <slot></slot>
    </div>

    <div v-if="!area" :id="areaName"></div>
  </main>
</template>

<script>
export default {
  name: "draggable-container",
  props: {
    container: { type: Number, default: -1 },
    move: { type: Boolean, default: false },
    top: { type: Number, default: 50 },
    left: { type: Number, default: 50 },
    unit: { type: String, default: "px" },
    area: { type: String, default: null },
    OffscreenX: { type: Number, default: 50 },
    OffscreenY: { type: Number, default: 50 },
    stack: { type: Boolean, default: false },
    spacer: { type: Number, default: 25 },
  },
  data() {
    return {
      instance: null,
      stateAnimationFrame: null,
      timeoutState_set_position: null,
      timeoutState_init_frame: null,
      checkResize: false,
      fromOffScreen: false,
      mustRestack: false,
      mustBeStack: this.stack,
      limit: {
        top: 81,
        left: 25,
        right: 25,
        bottom: -50,
      },
      zIndex: 0,
      beforeMove: { x: 50, y: 50 },
      scrollHeight: 0,
      scrollWidth: 0,
      scrollY: 0,
      clicked: null,
      clientRect: null,
      event: null,
      redraw: false,
      x: 0,
      y: 0,
    };
  },
  computed: {
    containerName() {
      return `cpn-dc-container-name-${this.instance}`;
    },
    areaName() {
      return !this.area ? `cpn-dc-area-name-${this.instance}` : `${this.area}`;
    },
    unit_metric() {
      return "px"; // le déplacement de fenetre semble n'accepter que les Pixels px
    },
    styleContainer() {
      const position = localStorage.getItem(this.containerName);

      const top =
        typeof position === "string"
          ? parseInt(JSON.parse(position).y) || this.top
          : this.top;
      const left =
        typeof position === "string"
          ? parseInt(JSON.parse(position).x) || this.left
          : this.left;

      const result = this.isMobileBrowser
        ? {}
        : {
            position: "absolute",
            top: top + this.unit_metric,
            left: left + this.unit_metric,
            margin: 0,
            padding: 0,
          };

      return result;
    },

    canMove() {
      return this.move || false;
    },
    containerPosition() {
      return document.getElementById(this.containerName);
    },
    areaDraggable() {
      return document.getElementById(this.areaName);
    },

    isMobileBrowser() {
      const isMobileBrowser = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
      ].some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      });
      return isMobileBrowser || false;
    },
  },

  created() {
    if (isNaN(this.$root.draggable_container)) {
      this.$root.draggable_container = 0;
    }
    if (isNaN(this.$root.draggableSpacerContainerY)) {
      this.$root.draggableSpacerContainerY = 0;
    }
    if (isNaN(this.$root.zIndexContainer)) {
      this.$root.zIndexContainer = 0;
    }
    if (!this.$root.listToStack) {
      this.$root.listToStack = [];
    }

    this.$root.draggable_container = this.$root.draggable_container + 1 || 1;

    // Si aucun identifiant de container, alors on le génére automatiquement
    this.instance =
      this.container === -1 ? this.$root.draggable_container : this.container;
  },
  mounted() {
    if (!this.containerPosition || !this.areaDraggable || this.isMobileBrowser)
      return;

    this.containerPosition.addEventListener("mousedown", this.onMouseDown);
    document.addEventListener("mousemove", this.onMouseMove);
    document.addEventListener("mouseup", this.onMouseUp);
    document.addEventListener("scroll", this.onScroll);
    window.addEventListener("resize", this.onResize);

    this.$root.$on("DraggableContainerReload", this.onReload);

    this.timeoutState_init_frame = setTimeout(() => {
      this.loopFrame();
      this.stackOffScreen();
    }, 2000);
  },
  beforeDestroy() {
    if (!this.containerPosition || !this.areaDraggable || this.isMobileBrowser)
      return;

    this.containerPosition.removeEventListener("mousedown", this.onMouseDown);
    document.removeEventListener("mousemove", this.onMouseMove);
    document.removeEventListener("mouseup", this.onMouseUp);
    document.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("resize", this.onResize);

    this.$root.draggableSpacerContainerY = this.$root.draggable_container = this.$root.zIndexContainer = 0;
    this.$root.listToStack = [];
    cancelAnimationFrame(this.stateAnimationFrame);
    clearTimeout(this.timeoutState_init_frame);
  },

  methods: {
    onReload(event = null) {
      if (this.fromOffScreen) {
        this.mustRestack = true;
      }
    },

    onMouseDown(event) {
      this.getPosition(event);

      this.clicked = {
        x: this.x,
        y: this.y,
        isMoving: this.canMove,
      };
      this.beforeMove = {
        x: this.clientRect.x + window.scrollX || 100,
        y: this.clientRect.y + window.scrollY || 100,
      };
      this.containerPosition.style["z-index"] = 1020;
      event.preventDefault();
    },

    onMouseMove(event) {
      this.getPosition(event);
      this.event = event;
      this.redraw = true;
    },

    onMouseUp(event) {
      this.scrollY = 0;
      this.getPosition(event);
      if (this.clicked && this.clicked.isMoving) {
        this.checkPosition(false);
      }
      this.clicked = null;
      this.containerPosition.style["z-index"] = this.zIndex;
    },

    onScroll(event) {
      // this.scrollY = window.scrollY;
    },

    onResize(event) {
      this.checkResize = true;
    },

    calcOffScreenWindows() {
      this.$root.draggableSpacerContainerY += this.spacer;

      this.zIndex = this.$root.zIndexContainer += 1;
      this.containerPosition.style["z-index"] = this.zIndex;

      this.beforeMove = {
        x: this.OffscreenX,
        y: this.OffscreenY + parseInt(this.$root.draggableSpacerContainerY),
      };
    },

    checkOffScreen(x = 0, y = 0) {
      if (
        x > this.areaDraggable.clientWidth ||
        y > this.areaDraggable.clientHeight ||
        this.mustBeStack
      ) {
        if (this.mustBeStack) this.mustBeStack = false;

        this.calcOffScreenWindows();

        x = this.beforeMove.x;
        y = this.beforeMove.y;
      }

      return { x, y };
    },

    stackOffScreen(dontSave = true) {
      const clientRect = this.containerPosition.getBoundingClientRect();

      const beforePosition = {
        x: clientRect.x + window.scrollX,
        y: clientRect.y - window.scrollY,
      };

      const { x, y } = this.checkOffScreen(beforePosition.x, beforePosition.y);

      if (x != beforePosition.x || y != beforePosition.y) {
        this.containerPosition.style.transition = "0.25s";
        clearTimeout(this.timeoutState_set_position);
        this.timeoutState_set_position = setTimeout(() => {
          this.containerPosition.style.transition = "0s";
        }, 500);
        this.fromOffScreen = true;
        this.setPosition(this.containerPosition, x, y, false, dontSave);
      }
    },

    toStack() {
      if (
        typeof this.$root.listToStack === "object" &&
        !this.fromOffScreen &&
        this.$root.listToStack.indexOf(this.instance) != -1
      ) {
        this.$root.listToStack.splice(
          this.$root.listToStack.indexOf(this.instance),
          1
        );

        this.calcOffScreenWindows();
        this.fromOffScreen = true;
        this.setPosition(
          this.containerPosition,
          window.innerWidth,
          0,
          true,
          true
        );
      }
    },

    getPosition(event) {
      this.scrollHeight = this.areaDraggable.scrollHeight;
      this.scrollWidth = this.areaDraggable.scrollWidth;
      this.clientRect = this.containerPosition.getBoundingClientRect();
      this.x = event.clientX - this.clientRect.left - window.scrollX;
      this.y = event.clientY - this.clientRect.top - window.scrollY;
    },

    checkPosition(dontSave = false) {
      const clientRect = this.clientRect;

      if (clientRect.left < this.limit["left"]) {
        this.resetPosition(dontSave);
      } else if (clientRect.right > window.innerWidth - this.limit["right"]) {
        this.resetPosition(dontSave);
      } else if (
        clientRect.bottom >
        this.scrollHeight - window.scrollY - this.limit["bottom"]
      ) {
        this.resetPosition(dontSave);
      } else if (clientRect.top < this.limit["top"] - window.scrollY) {
        this.resetPosition(dontSave);
      } else {
        if (this.fromOffScreen) {
          this.$root.draggableSpacerContainerY = this.$root.zIndexContainer = this.zIndex = 0;
          this.fromOffScreen = false;
          this.$root.$emit("DraggableContainerReload", true);
        }
      }
    },

    resetPosition(dontSave = false) {
      this.setPosition(
        this.containerPosition,
        this.beforeMove.x,
        this.beforeMove.y,
        true,
        dontSave
      );
    },

    setPosition(element, x, y, reset = false, dontSave = false) {
      if (reset) {
        this.containerPosition.style.transition = "0.25s";
        x = this.beforeMove.x;
        y = this.beforeMove.y;

        clearTimeout(this.timeoutState_set_position);
        this.timeoutState_set_position = setTimeout(() => {
          this.containerPosition.style.transition = "0s";
        }, 500);
      }

      element.style.left = x + this.unit_metric;
      element.style.top = y + this.unit_metric;

      if (!dontSave) {
        localStorage.setItem(
          this.containerName,
          JSON.stringify({
            x,
            y,
          })
        );
      }
    },

    loopFrame() {
      this.stateAnimationFrame = requestAnimationFrame(this.loopFrame);
      this.toStack();

      if (!this.redraw) return;
      this.redraw = false;

      if (this.checkResize) {
        this.checkResize = false;
        return this.stackOffScreen();
      }

      if (this.clicked && this.clicked.isMoving) {
        return this.setPosition(
          this.containerPosition,
          this.event.clientX - this.clicked.x,
          this.event.clientY - this.clicked.y + this.scrollY,
          false
        );
      }

      if (this.mustRestack) {
        this.mustRestack = false;

        this.calcOffScreenWindows();
        this.setPosition(
          this.containerPosition,
          this.beforeMove.x,
          this.beforeMove.y,
          true,
          true
        );
      }

      this.containerPosition.style.cursor = this.canMove ? "move" : "default";
    },
  },
};
</script>

<style scoped>
.thumbs {
  width: 200px;
  height: 26px;
  overflow: hidden;
}
</style>